<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label mx-5" style="font-weight: bold; color: #464E5F">
          Perundang-undangan
        </h3>
      </div>
      <div class="card-toolbar">
        <div><button></button></div>
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-center">
        <ClipLoader
          :loading="this.loading"
          class="custom-class"
          color="#18328d"
          size="150"
          sizeUnit="px"
        />
      </div>
      <div class="row">
        <div class="col-md-3" v-for="pilihan in pilihans" :key="pilihan.id">
          <router-link
            :to="{
              name: 'TabelUndangUndang',
              params: { text: pilihan.text, value: pilihan }
            }"
          >
            <b-card bg-variant="default" class="card-customs">
              <h5 class="font-utama">
                {{ pilihan.text }}
              </h5>
              <span
                class="svg-icon-primary svg-icon-5x d-block my-2 float-right"
              >
                <inline-svg src="media/svg/custom/palu.svg" />
              </span>
            </b-card>
          </router-link>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.card-customs {
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: 12rem;
  margin-top: 5%;
}
.card-customs:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.font-utama {
  font-weight: 600;
  font-size: 17px;
  color: #18328d;
}
.font-sukses {
  font-weight: 600;
  font-size: 17px;
  color: #219653;
}
.font-custom-info {
  font-weight: 600;
  font-size: 17px;
  color: #2d9cdb;
}
.font-custom-danger {
  font-weight: 600;
  font-size: 17px;
  color: #e31a1a;
}
</style>

<script>
import { handleErrors } from "@/core/appUtil/util";
import { ClipLoader } from "@saeris/vue-spinners";

let token = "";
export default {
  name: "CardGroup",
  components: {
    ClipLoader
  },
  data() {
    return {
      pilihan: null,
      pilihans: [],
      loading: true
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });

    fetch(
      encodeURI(
        process.env.VUE_APP_URL_LOCAL + `/master/jenis-perundangan?take=100`
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      }
    )
      .then(handleErrors)
      .then(response => response.json())
      .then(items => {
        const detailMenu = items.data;
        let selectedOptions;
        selectedOptions = [];
        detailMenu.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.pilihans = selectedOptions;
        this.loading = false;
      });
  }
};
</script>
